// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const URL = 'https://portal.nramiset.np.isccloud.io';
const STAGE = 'nramiset';
const ICCA_STAGE = 'ops';
const ICCA_ACCOUNT = 'sandbox-systemdevelopment';

export const environment = {
  production: false, //either true or false
  NG_APP_COGNITO_USERPOOL_ID: 'us-east-2_GcSIxO0wc',
  NG_APP_COGNITO_CLIENT_ID: '1vnvenv15n6kl0704e3jhbv277',
  NG_APP_COGNITO_REGION: 'us-east-2',
  APP_URL: `${URL}`,
  API_URL: `${URL}/v3/api`,
  SQLAAS_URL: `https://${ICCA_STAGE}.use1.ic.${ICCA_ACCOUNT}.isccloud.io`,
  VISIBLE_OFFERS: ['fhiraas','fhirserver','sqlaas','doc', 'sds'],
  STAGE: `${STAGE}`,
  ICCA_STAGE: `${ICCA_STAGE}`,
  OFFER_API: {
    sqlaas: `https://${ICCA_STAGE}.use1.ic.${ICCA_ACCOUNT}.isccloud.io`,
    doc: `https://${ICCA_STAGE}.use1.ic.${ICCA_ACCOUNT}.isccloud.io`,
    fhirserver: `https://${ICCA_STAGE}.use1.ic.${ICCA_ACCOUNT}.isccloud.io`,
    sds: 'https://mx06snt1ql.execute-api.us-east-1.amazonaws.com/dev',
  },
};
